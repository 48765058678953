import ReactPaginate from "react-paginate";

export const Paginator = ({ data, itemsPerPage, setPageNumber }) => {
  const onPageChange = ({ selected }) => {
    setPageNumber(selected + 1);
  };

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel=" ->"
      previousLabel="<-"
      pageCount={Math?.ceil((data || 1) / itemsPerPage)}
      onPageChange={onPageChange}
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      breakClassName={"pagination__count__break"}
      containerClassName={"pagination_buttons_container"}
      activeClassName={"active__page"}
      renderOnZeroPageCount={<h1>No Listing</h1>}
    />
  );
};
