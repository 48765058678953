import { Box, Flex, HStack, Img, Stack, Text } from '@chakra-ui/react';
import { nanoid } from '@reduxjs/toolkit';
import { BsDot } from 'react-icons/bs';
import { HiOutlineBookmark } from 'react-icons/hi';
import { EmptyState } from '../../../components/common/EmptyState';
import { FullPageLoader } from '../../../components/common/FullPageLoader';
import { PageWrapper } from '../../../components/common/PageWrapper';
import { formatInThousands } from '../../../utils/utils';
import { useGetFavouritesQuery } from '../../public-pages/home/components/service';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userRole } from '../../../redux/features/userSlice';
import { dynamicPathhandler } from '../../../utils/utils';
import { PROTECTED_PATHS, PUBLIC_PATHS } from '../../../app/routes';

const { FULL_CAR_DETAILS } = PUBLIC_PATHS;
const { VIEW_FULL_CAR_DETAILS } = PROTECTED_PATHS;

export const Favourites = () => {
  const { data: favourites, isLoading } = useGetFavouritesQuery();
  const navigate = useNavigate();
  const user = useSelector(userRole);
  const parentPath = dynamicPathhandler(FULL_CAR_DETAILS);
  const loggedinCustomrParentPath = dynamicPathhandler(VIEW_FULL_CAR_DETAILS);

  const handleNavigateToDetails = (car) => {
    const path = user ? loggedinCustomrParentPath : parentPath;
    navigate(`${path}/${car.id}`, { state: car });
  };

  return (
    <PageWrapper as={Stack} spacing="12" py="10" minH="100vh">
      {isLoading && <FullPageLoader />}
      {!isLoading && !favourites?.data?.length && <EmptyState msg="You Don't have any Favorite yet" />}
      {favourites?.data?.map((favourite) => (
        <Box
          key={nanoid()}
          onClick={() => handleNavigateToDetails(favourite)}
          cursor="pointer"
        >
          <HStack gap="2rem" border="2px solid #ececec">
            <Img
              borderRadius="5px"
              width="280px"
              objectFit="cover"
              h="180px"
              src={favourite?.images[0]?.url || '/images/vehicle.png'}
              alt="vehicle"
            />
            <Stack color="#9A9EA7" w="fit-content">
              <Text textStyle="h4">
                {favourite?.year} {favourite?.name}
              </Text>
              <Text fontSize="13px">
                {favourite?.Mileage === 'undefined' ? 'Unknown' : favourite?.Mileage} Miles
              </Text>
              <HStack>
                <Text fontSize="13px">{favourite?.exterior_color}</Text>
                <Text fontSize="13px" alignItems="center" display="flex">
                  <BsDot />
                  # {favourite?.ref_id}
                </Text>
                <Text
                  w="120px"
                  fontSize="13px"
                  alignItems="center"
                  display="flex"
                >
                  <BsDot /> {Number(favourite?.warranty) || 0}yrs warranty
                </Text>
              </HStack>
              <Text textStyle="h4">${formatInThousands(favourite?.price)}</Text>
            </Stack>

            <Flex flex="0.9" justify="flex-end">
              <Box bg="brand.500" color="white" p="3" borderRadius="6px">
                <HiOutlineBookmark size={25} />
              </Box>
            </Flex>
          </HStack>
        </Box>
      ))}
    </PageWrapper>
  );
};
