import { Circle, Flex, HStack, Img, Stack, Text } from '@chakra-ui/react';
import { BsDot } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PROTECTED_PATHS, PUBLIC_PATHS } from '../../../../app/routes';
import { PrimaryButton } from '../../../../components/common/CustomButtons';
import { fallBackImg } from '../../../../components/common/extras';
import { userRole } from '../../../../redux/features/userSlice';
import { dynamicPathhandler, formatInThousands } from '../../../../utils/utils';
import { AiOutlineHeart } from 'react-icons/ai';
import { useMarkAsFavouriteMutation } from '../../home/components/service';
import { errorToast, successToast } from '../../../../components/common/ToastHandler';

const { FULL_CAR_DETAILS } = PUBLIC_PATHS;
const { VIEW_FULL_CAR_DETAILS } = PROTECTED_PATHS;

export const SingleVehicle = ({ refetch, data, placeOrder }) => {
  const [markAsFavourite, { isLoading }] = useMarkAsFavouriteMutation();
  const navigate = useNavigate();
  const user = useSelector(userRole);
  const parentPath = dynamicPathhandler(FULL_CAR_DETAILS);
  const loggedinCustomrParentPath = dynamicPathhandler(VIEW_FULL_CAR_DETAILS);

  // console.log('data', data);
  const markAsFav = async (car) => {
    await markAsFavourite({ inventory_id: car.id })
      .unwrap()
      .then((data) => successToast(data?.message?.status))
      .catch(() => errorToast('Check your connection'))
      .finally(() => refetch());
  };

  return (
    <Flex pt='5' gap='2rem' direction={['column', 'column', 'column', 'column', 'row']}>
      <Img borderRadius='5px' width='200px' objectFit='cover' h='160px' fallback={fallBackImg} src={data?.images[0]?.url || '/images/vehicle.png'} alt='vehicle' />
      <Stack color='#9A9EA7' w='fit-content'>
        <Text textStyle='h4'>{data ? `${data?.year} ${data?.name}` : '2013 Ford Matrix'}</Text>
        <HStack fontWeight='700' color='#333'>
          <Text>{data?.make?.name}</Text>
          <Text>{data?.model?.name}</Text>
        </HStack>
        <Text fontSize={'13px'}>{formatInThousands(data?.Mileage || 11475)} Miles</Text>
        <HStack>
          <Text fontSize={'13px'}>{data?.color || 'Blue'}</Text>
          <Text fontSize={'13px'} alignItems='center' display='flex'>
            {' '}
            <BsDot />#{data?.ref_id || 20199832}
          </Text>
          <Text w='110px' fontSize={'13px'} alignItems='center' display='flex'>
            <BsDot /> {data?.warranty === 'no warranty' ? 'No waranty' : data?.warranty === '1' ? '1yr warranty' : `${data?.warranty}yrs warranty`}
          </Text>
        </HStack>
        <Flex pos='relative'>
          <Text textStyle='h4'>${formatInThousands(data?.price || 41400)}</Text>
          <Circle onClick={() => markAsFav(data)} className='trending__like' color='brand.500' bg={data?.favorite ? 'brand.400' : 'lightGray'}>
            {isLoading ? <Text lineHeight={1}>...</Text> : <AiOutlineHeart size={16} />}
          </Circle>
        </Flex>
      </Stack>

      {!placeOrder && (
        <Flex alignSelf={['start', 'start', 'start', 'flex-end']} w='200px' justify={['start', 'start', 'start', 'flex-end']}>
          <PrimaryButton
            w='fit-content'
            px='5'
            fontSize='14px'
            onClick={() => navigate(!user ? `${parentPath}/${data.id}` : `${loggedinCustomrParentPath}/${data.id}`, { state: data })}
          >
            View Details
          </PrimaryButton>
        </Flex>
      )}

      {/* {placeOrder && (
        <Flex flex='1' justify={['flex-start', '', '', '', 'flex-end']}>
          <PrimaryButton w='fit-content' px='5' fontSize='14px' onClick={() => navigate(FULL_CAR_DETAILS)}>
            Place Order
          </PrimaryButton>
        </Flex>
      )} */}
    </Flex>
  );
};
