import {
  Box,
  Button,
  Flex,
  FormControl,
  HStack,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { AiFillCar } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AdminFooter } from "../../../../components/common/AdminFooter";
import { PrimaryButton } from "../../../../components/common/CustomButtons";
import { FullPageLoader } from "../../../../components/common/FullPageLoader";
import { PageWrapper } from "../../../../components/common/PageWrapper";
import { userRole } from "../../../../redux/features/userSlice";
import { useGetCarFullDetailsQuery } from "../../../public-pages/inventory/service";
import { listingTabs } from "../../add-new-vehicle/components/extras";
import { useMultiStepForm } from "../../add-new-vehicle/components/useMultiStepForm";
import { updateVehicleListing } from "../service/listingApi";
import { EditDescribeYourVahicle } from "./EditDescribeYourVehicle";
import { EditListingHeader } from "./EditListingHeader";
import { EditListingInformation } from "./EditListingInformation";
import { EditMajorFeatures } from "./EditMajorFeatures";
import { EditUploadedPhotos } from "./EditUploadedPhotos";

export const EditListing = () => {
  const { carID } = useParams();
  const user = useSelector(userRole);
  const { data, isLoading, refetch } = useGetCarFullDetailsQuery(carID);
  const [carDetails, setCarDetails] = useState({});
  const [isMobile] = useMediaQuery("(max-width: 920px)");
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageToRemove, setImageToRemove] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setCarDetails(data?.data);
  }, [data?.data]);


  const { step, isFirstStep, isLastStep, back, next, currentIndex } =
    useMultiStepForm([
      <EditMajorFeatures
        carDetails={carDetails}
        setCarDetails={setCarDetails}
      />,
      <EditListingInformation
        carDetails={carDetails}
        setCarDetails={setCarDetails}
      />,
      <EditUploadedPhotos
        setImageToRemove={setImageToRemove}
        setPhotos={setPhotos}
        carDetails={carDetails}
      />,
      <EditDescribeYourVahicle
        carDetails={carDetails}
        setCarDetails={setCarDetails}
      />,
    ]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isLastStep) return next();

    const formData = new FormData();

    

    photos.forEach(
      (photo) =>
        photo.file && formData.append("additional_images[]", photo.file)
    );
    imageToRemove.forEach(
      (id) =>
        id?.removed_image_id &&
        formData.append("removed_ids[]", id?.removed_image_id)
    );
    
    formData.append("interior_color", carDetails?.interior_color);
    formData.append("exterior_color", carDetails?.exterior_color);
    formData.append("year", carDetails.year);
    formData.append("vin", carDetails.vin);
    formData.append("mileage", carDetails?.Mileage);
    formData.append("body_style", carDetails.body_style);
    formData.append("door", carDetails.door);
    formData.append("category", carDetails.category);
    formData.append("drive_train", carDetails.drive_train);
    formData.append("fuel_type", carDetails.fuel_type);
    formData.append("transmission", carDetails.transmission);
    formData.append("drive", "no idea");
    formData.append("seat", carDetails.seat);
    formData.append("availability", carDetails.availability || 1);
    formData.append("model_id", carDetails.model_id);
    formData.append("name", carDetails.name);
    formData.append("price", carDetails.price);
    formData.append("description", carDetails.description);
    formData.append("warranty", carDetails.warranty);
    formData.append("color", "red");
    formData.append("_method", "PUT");


    const payload = { formData, id: carDetails.id };
    
    updateVehicleListing(payload, navigate, user, setLoading, refetch);
  };

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Stack bg="white">
      <EditListingHeader />
      {/* //forms */}
      <PageWrapper>
        <Flex
          py="10"
          columnGap={"4%"}
          direction={["column", "column", "column", "row"]}
        >
          <Stack
            spacing="5"
            pt={[0, "4rem"]}
            pb={["2rem"]}
            w={["100%", "100%", "100%", "250px", "220px"]}
            direction={["row", "row", "row", "column"]}
          >
            {listingTabs?.map((tab, i) => (
              <HStack
                key={nanoid()}
                fontSize={["0.8rem", "1rem"]}
                p="2"
                borderRadius="5px"
                className={currentIndex === i && "listing-active-tab"}
              >
                {!isMobile && <AiFillCar />}
                <Text>{tab?.title}</Text>
              </HStack>
            ))}
          </Stack>
          <Box w="100%" maxW="700px">
            <FormControl as="form" onSubmit={onSubmit}>
              <Box p="5%" borderRadius="5px" border="2px solid #eee">
                {step}
              </Box>

              <Flex justify="flex-end" py="10" gap="1.5rem">
                {!isFirstStep && (
                  <>
                    <Button
                      w="150px"
                      type="button"
                      bg="none"
                      color="brand.500"
                      border="2px solid"
                      borderColor="brand.100"
                      onClick={back}
                    >
                      Previous
                    </Button>
                  </>
                )}

                <PrimaryButton
                  w="150px"
                  type="submit"
                  isLoading={isLastStep ? loading : false}
                >
                  {isLastStep ? "Finish" : "Next"}
                </PrimaryButton>
              </Flex>
            </FormControl>
          </Box>
        </Flex>
      </PageWrapper>

      <AdminFooter />
    </Stack>
  );
};
