import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ENDPOINTS } from "../../../services/routes.constants";

export const superAdminApiSlice = createApi({
  reducerPath: "superAdminApiSliceper",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.persistedReducer?.user?.user?.data?.token;
      token && headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["superAdminApiSliceper"],

  endpoints: (builder) => ({
    addAdmin: builder.mutation({
      query: (body) => ({
        url: API_ENDPOINTS.SUPER_ADMIN_ADD_ADMIN,
        body,
        method: "POST",
      }),
      invalidatesTags: ["superAdminApiSliceper"],
    }),

    getUserByRole: builder.query({
      query: (role) => API_ENDPOINTS.SUPER_ADMIN_GET_USERS_BY_ROLE(role),
      transformResponse: ({ data }) =>
        data?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at)),
      providesTags: ["superAdminApiSliceper"],
    }),

    deactivateUser: builder.mutation({
      query: (id) => ({
        method: "GET",
        url: API_ENDPOINTS.DISABLE_USER_ACCOUNT(id),
      }),
      invalidatesTags: ["superAdminApiSliceper"],
    }),

    reactivateUser: builder.mutation({
      query: (id) => ({
        method: "GET",
        url: API_ENDPOINTS.REACTIVATE_USER(id),
      }),
      invalidatesTags: ["superAdminApiSliceper"],
    }),

    blockUser: builder.mutation({
      query: (userId) => ({
        url: API_ENDPOINTS.SUPER_ADMIN_BLOCK_USER(userId),
        method: "GET",
      }),
      invalidatesTags: ["superAdminApiSliceper"],
    }),

    deleteUser: builder.mutation({
      query: (id) => ({
        url: API_ENDPOINTS.DELETE_USER(id),
        method: "GET",
      }),
      invalidatesTags: ["superAdminApiSliceper"],
    }),
  }),
});

export const {
  useAddAdminMutation,
  useGetUserByRoleQuery,
  useDeactivateUserMutation,
  useBlockUserMutation,
  useDeleteUserMutation,
  useReactivateUserMutation,
} = superAdminApiSlice;
