import React, { useState } from "react";
import { useGetMessagesQuery } from "../../../../redux/features/api/messagesApiSlice";
import { FullPageLoader } from "../../../../components/common/FullPageLoader";
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { nanoid } from "@reduxjs/toolkit";
import { formatDate } from "../../../../utils/utils";
import CustomModal from "../../../../components/common/CustomModal";
import { Paginator } from "../../../../components/common/Paginator";
import { BsThreeDots } from "react-icons/bs";
import { ContactMessageDetails } from "./ContactMessageDetails";
import { RxDotFilled } from "react-icons/rx";

export const MessagesTable = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const itemsPerPage = 10;
  const { data, isLoading } = useGetMessagesQuery({
    page: pageNumber,
    limit: itemsPerPage,
  });
  const allMessages = data?.data;
  const totalPages = data?.totalPages;

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <TableContainer
        py="10"
        bg="white"
        border="1px solid #ededed"
        borderRadius="5px"
      >
        <Table variant="Unstyled" size="lg">
          <Thead>
            <Tr>
              <Th pl="5" pr="0"></Th>
              <Th>Date Sent</Th>
              <Th>Customer name</Th>
              <Th>Email Address</Th>
              <Th>Message</Th>
            </Tr>
          </Thead>
          <Tbody fontSize="14px">
            {allMessages?.map((message, i) => (
              <Tr key={nanoid()}>
                <Td pl="5" pr="0">
                  {message?.status ? (
                    <RxDotFilled color="#eee" size={20} />
                  ) : (
                    <RxDotFilled color="green" size={20} />
                  )}
                </Td>
                <Td>{formatDate(message?.created_at)}</Td>
                <Td>{message?.name}</Td>
                <Td>{message?.email}</Td>
                <Td>{`${message?.message.substring(0, 15)}...`}</Td>
                <Td>
                  <CustomModal
                    btnTitle={<BsThreeDots color="black" size={25} />}
                  >
                    <ContactMessageDetails message={message} />
                  </CustomModal>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Flex justify="center">
          <Paginator
            data={totalPages}
            itemsPerPage={itemsPerPage}
            setPageNumber={setPageNumber}
          />
        </Flex>
      </TableContainer>
    </Box>
  );
};
