import { Stack, StackDivider } from "@chakra-ui/react";
import { nanoid } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { EmptyState } from "../../../../components/common/EmptyState";
import { FullPageLoader } from "../../../../components/common/FullPageLoader";
import { Paginator } from "../../../../components/common/Paginator";
import { useGetAllCarsInventoryQuery } from "../service";
import { SingleVehicle } from "./SingleVehicle";

export const Vehicle = ({ params, searchResult }) => {
  const {
    data: allCarsInventory,
    isLoading,
    refetch,
  } = useGetAllCarsInventoryQuery();
  const [pageNumber, setPageNumber] = useState(1);
  const itemsPerPage = 5;

  const dataToDisplay =
    params && Object.keys(params).length > 0
      ? searchResult?.data
      : allCarsInventory?.data;

  const totalItems = dataToDisplay?.length || 0;

  const displayedListings =
    dataToDisplay?.slice(
      (pageNumber - 1) * itemsPerPage,
      pageNumber * itemsPerPage
    ) || [];

  useEffect(() => {
    setPageNumber(1);
  }, [allCarsInventory, searchResult]);

  useEffect(() => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    if (pageNumber > totalPages) {
      setPageNumber(totalPages);
    }
  }, [totalItems, pageNumber]);

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Stack spacing="5" divider={<StackDivider borderColor="gray.300" />}>
      {displayedListings.map((car) => (
        <SingleVehicle refetch={refetch} data={car} key={nanoid()} />
      ))}

      {!displayedListings.length && !isLoading && (
        <EmptyState msg={"Not Found"} />
      )}

      {totalItems > 0 && (
        <Paginator
          data={totalItems}
          setPageNumber={setPageNumber}
          itemsPerPage={itemsPerPage}
        />
      )}
    </Stack>
  );
};
