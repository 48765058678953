import axios from "axios";
import { PROTECTED_PATHS } from "../../../../app/routes";
import { errorToast, successToast } from "../../../../components/common/ToastHandler";
import { API_ENDPOINTS } from "../../../../services/api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const listingApiSlice = createApi({
  reducerPath: "listingApiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState()?.persistedReducer?.user?.user?.data?.token;
      token && headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["ListingApiSlice"],

  endpoints: (builder) => ({
    getListings: builder.query({
      query: ({ category, page = 1, limit = 10 }) => 
        API_ENDPOINTS.GET_CAR_BY_CATEGORY(category, page, limit),
      providesTags: ["ListingApiSlice"],
    }),

    updateAvailability: builder.mutation({
      query: (id) => ({
        method: "GET",
        url: API_ENDPOINTS.UPDATE_AVAILABILITY(id),
      }),
      invalidatesTags: ["ListingApiSlice"],
    }),

    deleteVehicle: builder.mutation({
      query: (carId) => ({
        method: "DELETE",
        url: API_ENDPOINTS.DELETE_VEHICLE(carId),
      }),
      invalidatesTags: ["ListingApiSlice"],
    }),

    updateVehicle: builder.mutation({
      query: ({ formData, id }) => ({
        url: API_ENDPOINTS.UPDATE_VEHICLE(id),
        body: formData,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
          "Access-Control-Allow-headers": "Content-Type",
        },
      }),
      invalidatesTags: ["ListingApiSlice"],
    }),
  }),
});

export const {
  useGetListingsQuery,
  useUpdateAvailabilityMutation,
  useDeleteVehicleMutation,
  useUpdateVehicleMutation,
} = listingApiSlice;

export const updateVehicleListing = async (
  payload,
  navigate,
  user,
  setLoading,
  refetch
) => {
  const token =
    JSON.parse(JSON.parse(localStorage.getItem("persist:#gbe#xp&st"))?.user)
      ?.user?.data?.token || null;
  try {
    setLoading(true);
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}${API_ENDPOINTS.UPDATE_VEHICLE(
        payload.id
      )}`,
      payload.formData,
      {
        headers: {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
          "Access-Control-Allow-headers": "Content-Type",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    successToast("New Car Listed Updated");
    setLoading(false);
    navigate(
      user === "super-admin"
        ? PROTECTED_PATHS.SUPER_ADMIN_LISTING
        : PROTECTED_PATHS.ADMIN_LISTING
    );
    refetch();
  } catch (error) {
    errorToast();
    setLoading(false);
  }
};
