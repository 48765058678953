import {
  Button,
  Flex,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { nanoid } from "@reduxjs/toolkit";
import { Paginator } from "../../../components/common/Paginator";
import { PageWrapper } from "../../../components/common/PageWrapper";
import { useState } from "react";
import { FullPageLoader } from "../../../components/common/FullPageLoader";
import { formatDate } from "../../../utils/utils";
import { useGetAllSubscribersQuery } from "../../../redux/features/api/subscribersApiSlice";
import { CSVLink } from "react-csv";
import { BiCloudDownload } from "react-icons/bi";

export const Subscribers = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const itemsPerPage = 10;

  const { data, isLoading } = useGetAllSubscribersQuery({
    page: pageNumber,
    limit: itemsPerPage,
  });

  const subscribers = data?.data || [];
  const totalPages = data?.totalPages || 1;

  const headers = [
    { label: "Email", key: "email" },
    { label: "Date Signed Up", key: "date" },
  ];
  const csvData = subscribers.map((subscriber) => ({
    email: subscriber.email,
    date: formatDate(subscriber.created_at),
  }));

  if (isLoading) return <FullPageLoader />;

  return (
    <PageWrapper>
      <TableContainer>
        <HStack
          border="1px solid #eee"
          borderRadius="5px"
          justify="space-between"
          p="3"
        >
          <Text fontSize="1rem" fontWeight={600} color="blackAlpha.900">
            All Subscribers
          </Text>
          <CSVLink data={csvData} headers={headers}>
            <Button
              bg="brand.200"
              color="red.700"
              fontSize="sm"
              leftIcon={<BiCloudDownload size={20} />}
            >
              Download
            </Button>
          </CSVLink>
        </HStack>
        <Table variant={"unstyled"}>
          <Thead>
            <Tr>
              <Th>Date Signed Up</Th>
              <Th>Email</Th>
            </Tr>
          </Thead>

          <Tbody fontSize="14px" minH="80vh">
            {subscribers.map((subscriber) => (
              <Tr
                key={nanoid()}
                _hover={{ bg: "gray.100" }}
                transitionDuration={"500ms"}
              >
                <Td>{formatDate(subscriber.created_at)}</Td>
                <Td>{subscriber.email}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Flex py="10">
          <Paginator
            data={totalPages}
            itemsPerPage={itemsPerPage}
            setPageNumber={setPageNumber}
          />
        </Flex>
      </TableContainer>
    </PageWrapper>
  );
};
