import { Circle, Img, Stack, Text } from '@chakra-ui/react';
import { AiOutlineHeart } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PUBLIC_PATHS, PROTECTED_PATHS } from '../../../../app/routes';
import { fallBackImg } from '../../../../components/common/extras';
import { errorToast, successToast } from '../../../../components/common/ToastHandler';
import { userRole } from '../../../../redux/features/userSlice';
import { formatInThousands, dynamicPathhandler } from '../../../../utils/utils';
import { useMarkAsFavouriteMutation } from './service';

export const TrendingCard = ({ trendingCar, refetch }) => {
  const user = useSelector(userRole);
  const navigate = useNavigate();
  const [markAsFavourite, { isLoading }] = useMarkAsFavouriteMutation();

  const parentPath = dynamicPathhandler(PUBLIC_PATHS.FULL_CAR_DETAILS);
  const loggedinCustomerParentPath = dynamicPathhandler(PROTECTED_PATHS.VIEW_FULL_CAR_DETAILS);

  const handleNavigateToDetails = () => {
    const path = user ? loggedinCustomerParentPath : parentPath;
    navigate(`${path}/${trendingCar.id}`, { state: trendingCar });
  };

  const markAsFav = async (trendingCar) => {
    if (!user) return navigate(PUBLIC_PATHS.LOGIN);

    await markAsFavourite({ inventory_id: trendingCar.id })
      .unwrap()
      .then((data) => successToast(data?.message?.status))
      .catch(() => errorToast())
      .finally(() => refetch());
  };

  return (
    <Stack shadow="sm" bg="white" w="fit-content" borderRadius="3px" pos="relative">
      <Img
        onClick={handleNavigateToDetails}
        fallback={fallBackImg}
        w="260px"
        h="220px"
        borderTopRadius="3px"
        objectFit="cover"
        src={trendingCar?.images[0]?.url}
        alt="trending car"
        cursor="pointer"
      />

      <Circle
        onClick={() => markAsFav(trendingCar)}
        className="trending__like"
        color="brand.500"
        bg={trendingCar?.favorite ? 'brand.400' : 'lightGray'}
      >
        {isLoading ? <Text lineHeight={1}>...</Text> : <AiOutlineHeart size={16} />}
      </Circle>

      <Stack p="2" onClick={handleNavigateToDetails} cursor="pointer">
        <Text textStyle="h4">{trendingCar?.name}</Text>
        <Text textStyle="h4">${formatInThousands(trendingCar?.price)}</Text>
      </Stack>
    </Stack>
  );
};
