import { Circle, Flex, HStack, Select, Stack, Text } from "@chakra-ui/react";
import { PageWrapper } from "../../../components/common/PageWrapper";
import { border } from "../../../utils/theme";
import { RiArrowDownSLine } from "react-icons/ri";
import { SingleListedVehicle } from "./components/SingleListedVehicle";
import { nanoid } from "@reduxjs/toolkit";
import { useState, useEffect } from "react";
import { Paginator } from "../../../components/common/Paginator";
import { EmptyState } from "../../../components/common/EmptyState";
import { FullPageLoader } from "../../../components/common/FullPageLoader";
import { useGetListingsQuery } from "./service/listingApi";

export const Listings = () => {
  const [listingCategory, setListingCategory] = useState("all");
  const [pageNumber, setPageNumber] = useState(1);
  const itemsPerPage = 10;

  const { data: listing, isLoading } = useGetListingsQuery({
    category: listingCategory,
    page: pageNumber,
    limit: itemsPerPage,
  });

  const totalListings = listing?.data || [];
  const totalPages = Math.ceil((listing?.meta?.total || 0) / itemsPerPage) || 1;

  useEffect(() => {
    setPageNumber(1);
  }, [listingCategory]);

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <PageWrapper pb="20">
      <HStack
        border={border}
        p="3"
        borderTopRadius="5px"
        justify="space-between"
      >
        <HStack gap="1.5rem">
          <Text textStyle="h3" fontSize="17px">
            Listings
          </Text>
          <Select
            onChange={(e) => setListingCategory(e.target.value)}
            bg="white"
            w="150px"
            icon={<DropdownIcon icon={<RiArrowDownSLine />} />}
          >
            <option value={"all"}>All</option>
            <option value="trending">Trending</option>
            <option value="popular">Popular</option>
            <option value="used">Used</option>
            <option value="new">New</option>
          </Select>
        </HStack>
      </HStack>
      <Stack spacing="10">
        {totalListings.length > 0 ? (
          totalListings.map((item) => (
            <SingleListedVehicle key={nanoid()} item={item} />
          ))
        ) : (
          <EmptyState h="50vh" size="10rem" msg="No Current Listings" />
        )}
      </Stack>
      {totalListings.length > 0 && (
        <Flex>
          <Paginator
            data={totalPages}
            setPageNumber={setPageNumber}
            itemsPerPage={itemsPerPage}
          />
        </Flex>
      )}
    </PageWrapper>
  );
};

export function DropdownIcon({ icon, ...props }) {
  return (
    <Circle p="1" bg="brand.100" color="brand.500" {...props}>
      {icon}
    </Circle>
  );
}
